<template>
  <div class="course-item">
    <van-cell
      class="article-item"
      @click="detail(item)"
      v-for="(item, index) in hots"
      :key="index"
    >
      <div slot="title" class="title van-multi-ellipsis--l2">
        <van-image lazy-load class="item-img" :src="item.image" fit="cover">
          <template v-slot:default>
            <span class="vip-icon" v-if="item.is_vip == 1 && item.is_free == 1"
              >VIP</span
            >
            <span v-if="item.lessonCount > 1" class="lessonCount"
              >{{ item.lessonCount }} {{ checkType(item.type) }}</span
            >
          </template>
        </van-image>
        <div class="sub-info">
          <div class="sub-title">{{ item.title }}</div>
          <div class="updated_at">
            <span class="price">
              <template
                v-if="
                  (item.is_vip == 1 && item.is_free == 1 && isShowPrice) ||
                  (item.is_vip == 2 && item.is_free == 1)
                "
              >
                <span>
                  <i style="margin-left: 10px" class="icon-cny" />
                  {{ item.price | formatPrice(item, "price") }}
                </span>
                <span class="oldPrice">
                  <i style="margin-left: 10px" class="icon-cny" />
                  {{ item.old_price | formatPrice(item, "old_price") }}
                </span>
              </template>
            </span>
            <span
              ><van-icon name="clock-o" style="margin-left: 3px" />
              <span class="time">{{
                item.updated_at | datefmt("YYYY-MM-DD")
              }}</span></span
            >
            <span>
              <van-icon style="margin-left: 10px" name="browsing-history-o" />
              <span class="time">{{ item.views }}</span></span
            >
          </div>
          <div class="total_num" v-if="item.orderCount">
            {{ item.orderCount }} ادام ساتىپ الدى
          </div>
        </div>
      </div>
    </van-cell>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage'
export default {
  props: {
    hots: {
      type: Array
    },
    clickType: {
      type: String,
      default: 'course'
    }
  },
  data () {
    return {
      isShowPrice: ''
    }
  },
  created () {
    this.isShowPrice = !getItem('vipStatus')
  },
  filters: {
    formatPrice (value, data, type) {
      // 如果有限购活动执行以下代码
      if (data.seckill_day) {
        const limitedTime = data.seckill_day
          .split('&')[1]
          .toString()
          .replace(/-/g, '/')
        const vipTime = new Date(limitedTime).getTime() / 1000 + ' '
        const nowTime = new Date()
        const endTime = new Date(vipTime * 1000)
        const t = endTime.getTime() - nowTime.getTime()
        if (t > 0) {
          switch (type) {
            case 'price':
              return data.seckill_price
            case 'old_price':
              return data.price
          }
        } else {
          return value
        }
      } else {
        return value
      }
    }
  },
  methods: {
    detail ({ id, type, lessonCount }) {
      if (lessonCount === 0) {
        this.$toast('قازىرشە مازمۇن قوسىلمادى')
        return
      }
      let path = ''
      if (this.clickType === 'course') {
        path = { name: 'detail', params: { parent_id: id, type } }
      } else {
        path = {
          path: '/topic',
          query: {
            id
          }
        }
      }
      this.$router.push(path)
      document.documentElement.scrollTop = 0
    },

    checkType (type) {
      let name = ''
      switch (type) {
        case 'article':
          name = 'ماقالا'
          break
        case 'audio':
          name = 'اۋديو'
          break
        case 'video':
          name = 'ۆيديو'
          break
      }
      return name
    }
  }
}
</script>

<style lang="less" scoped>
.article-item {
  direction: rtl;
  padding: 14px 10px;

  .title {
    display: flex;
    align-items: center;
    font-size: 26px;
    color: #3a3a3a;
    .item-img {
      width: 200px;
      height: 140px;
      flex-shrink: 0;
      margin-left: 20px;

      /deep/ .van-image__img {
        border-radius: 6px !important;
        object-fit: cover;
      }
    }

    .sub-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .sub-title {
        font-size: 28px;
      }

      .updated_at {
        display: flex;
        align-items: center;
        font-size: 24px;
        color: #999;
        direction: ltr;
        > span {
          display: inline-flex;
          align-items: center;
        }
        > span:last-of-type {
          display: inherit;
        }
        .time {
          font-family: Arial, Helvetica, sans-serif;
          margin-left: 6px;
        }
        .price {
          margin-right: auto;
          display: flex;
          align-items: baseline;
          > span {
            font-size: 28px;
            color: #ee0a24;
            font-weight: 600;
            font-family: Arial, Helvetica, sans-serif;
          }

          .oldPrice {
            text-decoration: line-through;
            font-size: 20px;
            color: #999;
            font-weight: normal;
            // font-style: italic;
          }
        }

        .checkType {
          background-color: #fff;
          color: #999;
          border: 1px solid #888;
          font-size: 24px;
          margin-left: 10px;
          border-radius: 4px;
          padding: 0 4px;
          height: 30px;
          line-height: 30px;
        }
      }

      .total_num {
        font-size: 24px;
        color: #ee0a24;
      }
    }
  }

  .van-cell__value {
    flex: unset;
    width: 232px;
    height: 146px;
    padding-left: 25px;
  }
}
</style>
